<template>
  <div>
    <informeHogarList />
  </div>
</template>

<script>
import informeHogarList from './components/InformeHogarList.vue'

export default {
  components: {
    informeHogarList,
  },
}
</script>

<style>

</style>
