<template>
  <b-button-group>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Ver informe hogar"
      @click="$emit('processGoToRead', data.item)"
    >
      <feather-icon
        icon="EyeIcon"
      />
    </b-button>

    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Descargar informe hogar"
      @click="$emit('processGoToDownload', data.item)"
    >
      <feather-icon
        icon="DownloadIcon"
      />
    </b-button>

    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Ver informe hogar alumno retirado"
      @click="$emit('processGoToReadRetirado', data.item)"
    >
      <feather-icon
        icon="UserXIcon"
      />
    </b-button>

  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
}
</script>
